import {mapGetters} from "vuex";

export default {
    computed:{
      ...mapGetters({
          blockedName:'config/blockedName'
      })
    },
    methods:{

    }
}
